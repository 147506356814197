import { CopyButton, Row, expandBaseWebBorder, expandBorderRadii } from "@gadgetinc/widgets";
import { DocsLightTheme } from "@gadgetinc/widgets/src/DocsTheme";
import { proseFamily } from "@gadgetinc/widgets/src/EditTheme";
import darkTheme from "@gadgetinc/widgets/src/code-block-themes";
import { TerminalIcon } from "@gadgetinc/widgets/src/icons/TerminalIcon";
import { styled, useStyletron, withStyle } from "baseui";
import React, { useCallback, type ReactNode } from "react";
import { prettifyCodeExample } from "state-trees/src/prettifyCodeExample";
import type { StyleObject } from "styletron-react";
import { CodeBlockTitleBar, CodeBlockTitleBarSpacer, handleCopy } from "web/src/components/markdown/CodeBlock";
import { CodeHighlight, useCodeHighlightTheme } from "web/src/components/markdown/CodeHighlight";
import { LanguageIndicator, languageLabel } from "web/src/components/markdown/LanguageIndicator";
import { Mermaid as MermaidBase } from "web/src/components/markdown/Mermaid";

export const Mermaid = (props: any) => {
  // Mermaid doesn't support SSR at all -- wrap it and check if we're SSRing the vite way, if so, no rendersies
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore we import this in the api context which makes TS sad as it's using the server side module system. since we don't ever render this api side, we don't care, but we can't convince ts to use a totally different module system than it knows its using server side, so we ignore the ts error
  return import.meta.env.SSR ? <div /> : <MermaidBase {...props} />;
};

// semicolons inside of object values of a styled component cause it to throw an error, this is probably due to the obect being iterated over and ; being append for each style rule
const proseFontFamily = proseFamily.replaceAll(";", "");

export const handleCopyWithToast = async (text: string) => {
  await handleCopy(
    text,

    <div
      style={{
        ...DocsLightTheme.typography.LabelXSmall,
        fontFamily: proseFontFamily,
        marginRight: DocsLightTheme.sizing.scale500,
        wordWrap: "normal",
      }}
    >
      Copied to clipboard.
    </div>
  );
};

export const CodeBlockTitle = styled("div", ({ $theme }) => ({
  flexGrow: 1,
  ...$theme.typography.LabelSmall,
  marginTop: "0",
  marginBottom: "0",
  color: $theme.colors.primary400,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

export const CodeBlockFilename = withStyle(CodeBlockTitle, ({ $theme }) => ({
  ...$theme.typography.MonoLabelSmall,
  fontWeight: 400,
}));

export const CodeBlockContainer = (props: { children: React.ReactNode; rawCode?: string; language?: string; $style?: StyleObject }) => {
  const [css, $theme] = useStyletron();

  return (
    <div
      data-docscode={props.rawCode}
      data-docscodelanguage={props.language}
      className={css({
        backgroundColor: darkTheme.plain.backgroundColor,
        ...expandBaseWebBorder($theme.borders.border100),
        ...expandBorderRadii($theme.borders.radius400),
        marginBottom: $theme.sizing.scale700,
        boxShadow: $theme.lighting.shadow400,
        ...props.$style,
      })}
    >
      {props.children}
    </div>
  );
};

const FILENAME_RE = /^\s*\/\/\s+in\s+([^\n]+?)\s*\n/m;
const shellLanguages = new Set(["sh", "sh-session", "shell", "shell-session", "bash"]);

export const CodeBlock = (props: {
  /** An english title for the codeblock */
  title?: React.ReactNode;
  /** A filename for the code contained in the codeblock */
  filename?: string;
  /** The code of the codeblock */
  children: string;
  /** The code block theme */
  kind?: "light" | "dark" | undefined;
  className?: string;
  /** What language to syntax highlight the code as */
  language?: string;
  hideLanguageLabel?: boolean;
  hideCopyButton?: boolean;
  style?: any;
  printWidth?: number;
}) => {
  const [css, $theme] = useStyletron();
  let language: string;
  let showLanguageLabel = !props.hideLanguageLabel;
  let hideCopyButton = props.hideCopyButton;
  let filename: ReactNode = props.filename;
  const rawCode = props.children;
  let body = props.children;

  const inferredTitleMatches = body.match(FILENAME_RE);
  if (inferredTitleMatches) {
    filename = inferredTitleMatches[1];
    body = body.replace(FILENAME_RE, "");
  }

  if (props.language) {
    language = props.language;
  } else if (props.className) {
    language = props.className.replace("language-", "");
  } else {
    language = "markdown";
    showLanguageLabel = false;
  }

  if (shellLanguages.has(language)) {
    showLanguageLabel = false;
    filename ??= (
      <Row $gap={$theme.sizing.scale200}>
        <TerminalIcon />
        terminal
      </Row>
    );
  }
  if (language.endsWith("-session")) {
    hideCopyButton = true;
  }

  language = language.toLowerCase();

  const { theme, labelColor } = useCodeHighlightTheme(props.kind);

  const printWidth = props.printWidth ?? 85;
  body = prettifyCodeExample(body, language, printWidth);

  const copyCode = useCallback(() => {
    void handleCopyWithToast(body);
  }, [body]);

  if (language == "mermaid") {
    return <Mermaid chart={body} />;
  }

  return (
    <CodeBlockContainer rawCode={rawCode} language={language} $style={{ backgroundColor: theme.plain.backgroundColor }}>
      <CodeBlockTitleBar>
        {props.title && <CodeBlockTitle>{props.title}</CodeBlockTitle>}
        {filename && <CodeBlockFilename $theme={$theme}>{filename}</CodeBlockFilename>}
        <CodeBlockTitleBarSpacer />
        {language && showLanguageLabel && <LanguageIndicator color={labelColor}>{languageLabel(language)}</LanguageIndicator>}
        {!hideCopyButton && <CopyButton onClick={copyCode} />}
      </CodeBlockTitleBar>

      <div
        className={css({
          overflowX: "auto",
          paddingLeft: $theme.sizing.scale400,
          paddingRight: $theme.sizing.scale400,
          paddingBottom: $theme.sizing.scale600,
          scrollbarColor: `${$theme.colors.primary300} transparent`,
        })}
      >
        <CodeHighlight kind={props.kind} language={language.toLowerCase()} className={props.className}>
          {body}
        </CodeHighlight>
      </div>
    </CodeBlockContainer>
  );
};
